import React, {useState, FC} from 'react';

type AnswerObject = {
  [choiceKey: string]: {givenChoice: string};
};

interface ChoiceMap {
  [key: string]: string[];
}

interface ComprehensionQuestionProps {
  text: string;
  choices: ChoiceMap;
  closeModal: () => void;
}

interface Segment {
  text: string;
  key: string;
  isBlank: boolean;
}

const ComprehensionQuestion: FC<ComprehensionQuestionProps> = ({
  text,
  choices,
  closeModal,
}) => {
  const initializeSegments = (text: string): Segment[] => {
    const choicePattern = /CHOICES(\S+)/g;
    let result;
    let lastIndex = 0;
    let segments: Segment[] = [];
    let choiceKeys: Set<string> = new Set();

    while ((result = choicePattern.exec(text)) !== null) {
      const [fullMatch, choiceKey] = result;

      if (lastIndex < result.index) {
        segments.push({
          text: text.substring(lastIndex, result.index),
          key: `text${lastIndex}`,
          isBlank: false,
        });
      }

      if (!choiceKeys.has(choiceKey)) {
        segments.push({
          text: '___',
          key: fullMatch,
          isBlank: true,
        });
        choiceKeys.add(choiceKey);
      }

      lastIndex = choicePattern.lastIndex;
    }

    if (lastIndex < text.length) {
      segments.push({
        text: text.substring(lastIndex),
        key: `text${lastIndex}`,
        isBlank: false,
      });
    }

    return segments;
  };

  const [segments, setSegments] = useState<Segment[]>(() =>
    initializeSegments(text),
  );
  const [activeChoiceKey, setActiveChoiceKey] = useState<string | null>(null);
  const [allBlanksFilled, setAllBlanksFilled] = useState(false);

  const checkAllBlanksFilled = (updatedSegments: Segment[]): boolean => {
    return updatedSegments.every(
      (segment) =>
        !segment.isBlank || (segment.isBlank && segment.text !== '___'),
    );
  };

  const handleBlankClick = (choiceKey: string) => {
    setActiveChoiceKey(choiceKey);
  };

  const handleChoiceClick = (choiceKey: string, word: string) => {
    const updatedSegments = segments.map((segment) =>
      segment.key === choiceKey ? {...segment, text: word} : segment,
    );
    setSegments(updatedSegments);
    setActiveChoiceKey(null);
    const filled = checkAllBlanksFilled(updatedSegments);
    setAllBlanksFilled(filled);
  };

  const renderChoiceButtons = (choiceKey: string) => {
    const currentChoices = choices[choiceKey] || [];
    const choicePrefixes = ['a)', 'b)', 'c)', 'd)'];

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          marginTop: 10,
        }}>
        {currentChoices.map((word, index) => (
          <button
            key={index}
            onClick={() => handleChoiceClick(choiceKey, word)}
            style={{
              background: 'none',
              border: 'none',
              textAlign: 'left',
              fontSize: 18,
              padding: 0,
              margin: 0,
            }}>
            {choicePrefixes[index]} {word}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div>
      {segments.map((segment, index) => (
        <React.Fragment key={index}>
          {segment.isBlank ? (
            segment.text === '___' ? (
              <span
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontSize: 18,
                }}
                onClick={() => handleBlankClick(segment.key)}>
                {segment.text}
              </span>
            ) : (
              <span
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  color: '#FFD252',
                  fontSize: 18,
                }}
                onClick={() => handleBlankClick(segment.key)}>
                {segment.text}
              </span>
            )
          ) : (
            <span style={{fontSize: 18}}>{segment.text}</span>
          )}
        </React.Fragment>
      ))}
      {activeChoiceKey && <div>{renderChoiceButtons(activeChoiceKey)}</div>}
      <div className="Buttons_Recording">
        <button onClick={closeModal} className="btn-record mt-5">
          <span>Send Answer</span>
        </button>
      </div>
    </div>
  );
};

export default ComprehensionQuestion;
