import {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {
  Button,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Tabs,
  Typography,
  Upload,
  UploadProps,
  Checkbox,
} from 'antd';

import {CreateAssetBlock} from '../../components';
import {
  Asset,
  AssetType,
  ProgramType,
  Question,
  QUESTION_TYPE,
  Test,
  TestTypes,
} from '../../graphql/API';
import {TestsService} from './TestsService';
import {validateCreateTest} from '../../utils/Validators';
import {CloseCircleFilled, UploadOutlined} from '@ant-design/icons';
import {StorageService, UploadFileType} from '../Lesson/StorageService';
import {UploadFile} from 'antd/lib/upload/interface';
import Text from 'antd/es/typography/Text';

const testService = new TestsService();
const storageService = new StorageService();

export const TestEdit = () => {
  const history = useHistory();
  let params: {testId: string} = useParams();

  const [fileAudio, setFileAudio] = useState<UploadFile>();

  const [uploading, setUploading] = useState(false);

  const {Title, Text} = Typography;
  const {TabPane} = Tabs;
  const emptyTestPane = {
    name: '',
    //@ts-ignore
    correctAsset: {
      type: AssetType.AUDIO,
      thumbnailUri: '',
      fileUri: '',
    },
    //@ts-ignore
    asset: {
      type: AssetType.PHOTO,
      thumbnailUri: '',
      fileUri: '',
    },
    programType: ProgramType.LANGUAGE,
    summary: 'Record with your own voice',
    description:
      'Press the photo or the words to listen to the pronunciation and then press the record button to record it with your own voice.',
    text: '',
    textAI: '',
    type: QUESTION_TYPE.READ_SENTENCE,
    choicesCount: [],
    correctIndex: {},
    originalTextAI: '',
  };

  //@ts-ignore
  const [panes, setPanes] = useState<Array<Question>>([emptyTestPane]);
  const [activeKey, setActiveKey] = useState<any>(0);
  const [selectedtest, setSelectedTest] = useState<Test>();
  const [fileSummaryVideo, setFileSummaryVideo] = useState<UploadFile>();

  const [testName, setTestName] = useState<string>('');
  const [testType, setTestType] = useState<TestTypes | undefined>(
    TestTypes.QUIZ,
  );
  // @ts-ignore
  const [testAsset, setTestAsset] = useState<Asset | undefined>({
    type: AssetType.VIDEO,
    thumbnailUri: '',
    fileUri: '',
  });
  const [testDescription, setTestDescription] = useState<string>(
    'Watch the video at least 2 times and after that record the questions’ answers.',
  );
  const [testSummary, setTestSummary] = useState<string>(
    'Watch carefully at least 2 times and answer the questions.',
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectChoice, setSelectChoice] = useState<string>('');

  const onChange = (activeKey: string) => {
    setActiveKey(parseInt(activeKey));
    if (panes[activeKey].type === QUESTION_TYPE.COMPREHENSION) {
      const firstKey = Object.keys(panes[activeKey].customChoices)[0];
      setSelectChoice(firstKey);
    }
    setFileSummaryVideo(null);
  };

  const onEdit = (targetKey: any, action: any) => {
    //this[action](targetKey);
    switch (action) {
      case 'add':
        add();
        break;
      default:
        console.log('CASE DEFAULT');
    }
  };

  useEffect(() => {
    testService
      .getTestById(params.testId)
      .then((tst: Test) => {
        // Transform questions if necessary
        const updatedQuestions = tst.questions.map((qst: Question) => {
          if (qst.type === QUESTION_TYPE.COMPREHENSION) {
            // Step 1: Clean up duplicates of CHOICES placeholders
            const removeDuplicateChoices = (text: string) => {
              const choiceRegex = /\b(CHOICES\d+)\b/g; // Matches CHOICES followed by a number
              const seenChoices = new Set<string>();

              return text
                .replace(choiceRegex, (match) => {
                  if (seenChoices.has(match)) {
                    return ''; // Remove duplicate placeholder
                  } else {
                    seenChoices.add(match);
                    return match; // Keep the first occurrence
                  }
                })
                .replace(/\s+/g, ' ')
                .trim(); // Clean up extra spaces
            };
            const clearText = removeDuplicateChoices(qst.text);
            // Step 2: Update the text with cleaned placeholders
            qst.text = clearText;
            qst.textAI = clearText;
            //@ts-ignore
            qst.originalTextAI = clearText;
            qst.customChoices =
              typeof qst.customChoices === 'string'
                ? JSON.parse(qst.customChoices)
                : qst.customChoices || {};
          }
          return qst;
        });

        setSelectedTest(tst);
        setTestName(tst.name);
        setTestType(tst.type);
        setPanes([...updatedQuestions]); // Set panes to transformed questions
        setTestDescription(tst.description);
        setTestSummary(tst.summary);
        setTestAsset(tst.asset);
        setActiveKey(0);

        // Initialize selectChoice if comprehension question exists
        const firstComprehension = updatedQuestions.find(
          (q) => q.type === QUESTION_TYPE.COMPREHENSION,
        );
        if (firstComprehension) {
          const firstKey = Object.keys(firstComprehension.customChoices)[0];
          setSelectChoice(firstKey);
        }

        setIsLoading(false);
      })
      .catch((error: any) => {
        console.error('Error fetching test:', error);
        setIsLoading(false);
      });
  }, [params.testId]);

  const add = () => {
    const activeKey = panes.length;
    const newPanes = panes;
    // @ts-ignore
    newPanes.push(emptyTestPane);
    setPanes(newPanes);
    setActiveKey(activeKey);
  };

  const propsAudioUpload: UploadProps = {
    type: 'select',
    accept: '.aac',
    listType: 'text',
    multiple: false,
    maxCount: 1,
    onRemove: (file) => {
      setFileAudio(null);
    },
    beforeUpload: (file) => {
      setFileAudio(file);

      return false;
    },
  };

  const propsSummaryVideo: UploadProps = {
    type: 'select',
    accept: '.mp4,.aac',
    listType: 'text',
    multiple: false,
    maxCount: 1,
    onRemove: (file) => {
      setFileSummaryVideo(null);
    },
    beforeUpload: (file) => {
      setFileSummaryVideo(file);

      return false;
    },
  };

  const handleUpload = async (
    fileUri: UploadFile,
    typeUpload: UploadFileType,
    index?: number,
  ) => {
    setUploading(true);
    console.log('fileUri.url', fileUri);
    try {
      let filePath: string;
      switch (typeUpload) {
        case UploadFileType.TEST_MEDIA:
          filePath = await storageService.uploadFile(
            fileUri,
            typeUpload,
            'test/media',
          );
          let tempAsset = {
            fileUri: filePath,
            type: AssetType.AUDIO,
            thumbnailUri: '',
          };
          let tempPanes = [...panes];
          // @ts-ignore
          tempPanes[index].correctAsset = tempAsset;
          setPanes(tempPanes);
          break;

        case UploadFileType.TEST_MEDIA_VIDEO_SESSION:
          filePath = await storageService.uploadFile(
            fileUri,
            typeUpload,
            'test/media',
          );
          let tempPaness = [...panes];
          // @ts-ignore
          tempPaness[index].videoAISessionUri = filePath;
          setPanes(tempPaness);
          break;

        default:
          break;
      }

      console.log('file_PATH_IUPLOADED', filePath);
      message.success('upload successfully.');

      setUploading(false);
    } catch (error) {
      console.log('error:', error);
      setUploading(false);
      message.error('upload failed.');
    }
  };

  return (
    <>
      <div style={{padding: 10}}>
        <Title style={{textAlign: 'center'}}>Edit test </Title>
        <>
          <div style={{marginTop: '20px', marginBottom: '20px'}}>
            <Text>Test name </Text>
            <Input
              value={testName}
              onChange={(e) => {
                setTestName(e.target.value);
              }}
              placeholder="Test name"
            />
          </div>
          {/* global question type selections */}
          <div style={{marginTop: '20px', marginBottom: '20px'}}>
            <Text style={{display: 'block'}}>Test type </Text>
            <Select
              value={testType}
              showSearch
              style={{width: '100%'}}
              placeholder="Select a test type"
              optionFilterProp="children"
              onChange={(selectedTestType: TestTypes) => {
                setTestType(selectedTestType);
              }}>
              <Select.Option value={TestTypes.QUIZ}>
                {TestTypes.QUIZ}
              </Select.Option>
              <Select.Option value={TestTypes.PLACEMENT_TEST}>
                {TestTypes.PLACEMENT_TEST}
              </Select.Option>
              <Select.Option value={TestTypes.STORY}>
                {TestTypes.STORY}
              </Select.Option>
            </Select>
          </div>
          {/* test description input */}
          <div style={{marginTop: '20px', marginBottom: '20px'}}>
            <Text>Test description </Text>
            <Input
              value={testDescription}
              onChange={(e) => {
                setTestDescription(e.target.value);
              }}
              placeholder="Test description"
            />
          </div>
          {/* test summery input */}
          <div style={{marginTop: '20px', marginBottom: '20px'}}>
            <Text>Test summary </Text>
            <Input
              value={testSummary}
              onChange={(e) => {
                setTestSummary(e.target.value);
              }}
              placeholder="Test summary"
            />
          </div>

          <CreateAssetBlock
            asset={testAsset}
            defaultAssetType={testAsset?.type ?? null}
            // @ts-ignore TODO be checked
            onChange={(selectedAsset) => {
              console.log('ONCHAAA', selectedAsset);
              setTestAsset(selectedAsset);
            }}
          />
        </>

        <Tabs
          type="card"
          onChange={onChange}
          activeKey={activeKey.toString()}
          onEdit={onEdit}>
          {panes?.map((pane, index) => (
            <TabPane
              tab={'question_' + index}
              key={index}
              closable={false}
              style={{
                border: '2px solid gray',
                padding: '10px',
              }}>
              <div style={{marginTop: '20px', marginBottom: '20px'}}>
                <Text>Question name</Text>
                <Input
                  value={pane?.name}
                  onChange={(e) => {
                    let tempPanes = [...panes];
                    tempPanes[index].name = e.target.value;
                    setPanes(tempPanes);
                  }}
                  placeholder="Question name"
                />
              </div>
              <div style={{marginTop: '20px', marginBottom: '20px'}}>
                <Text>Question Summary</Text>
                <Input
                  value={panes[index].summary}
                  onChange={(e) => {
                    let tempPanes = [...panes];
                    tempPanes[index].summary = e.target.value;
                    setPanes(tempPanes);
                  }}
                  placeholder="Question Summary"
                />
              </div>
              <div style={{marginTop: '20px', marginBottom: '20px'}}>
                <Text>Question description</Text>
                <Input
                  value={panes[index].description}
                  onChange={(e) => {
                    let tempPanes = [...panes];
                    tempPanes[index].description = e.target.value;
                    setPanes(tempPanes);
                  }}
                  placeholder="Question description"
                />
              </div>
              {testType === TestTypes.STORY && (
                <div style={{marginTop: '20px', marginBottom: '20px'}}>
                  <Text>Video AI Session url </Text>

                  {panes[index].videoAISessionUri ? (
                    <Row
                      style={{
                        display: 'flex',
                        // justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                      }}>
                      <Input
                        style={{
                          width: '80%',
                        }}
                        value={panes[index].videoAISessionUri}
                        onChange={(e) => {}}
                        placeholder="Basic usage"
                      />
                      {panes[index].videoAISessionUri && (
                        <CloseCircleFilled
                          style={{marginInline: 20}}
                          onClick={() => {
                            let tempPanes = [...panes];
                            tempPanes[index].videoAISessionUri = null;
                            setPanes(tempPanes);
                            setFileSummaryVideo(null);
                          }}
                        />
                      )}
                    </Row>
                  ) : (
                    <Row>
                      <Upload {...propsSummaryVideo}>
                        <Button
                          disabled={fileSummaryVideo ? true : false}
                          icon={<UploadOutlined />}>
                          Select File
                        </Button>
                      </Upload>

                      <Button
                        type="primary"
                        onClick={() => {
                          handleUpload(
                            fileSummaryVideo,
                            UploadFileType.TEST_MEDIA_VIDEO_SESSION,
                            index,
                          );
                        }}
                        disabled={!fileSummaryVideo}
                        loading={uploading}>
                        {uploading ? 'Uploading' : 'Start Upload'}
                      </Button>
                    </Row>
                  )}
                </div>
              )}
              {/* question type option selection */}
              <div style={{marginTop: '20px', marginBottom: '20px'}}>
                <Text style={{display: 'block'}}>Question type </Text>
                <Select
                  value={panes[index].type}
                  showSearch
                  style={{width: 300}}
                  placeholder="Select a question type"
                  optionFilterProp="children"
                  onChange={(selectedQuestionType: any) => {
                    let tempPanes = [...panes];
                    console.log('selectedQuestionType->', selectedQuestionType);
                    tempPanes[index].type = selectedQuestionType;
                    //@ts-ignore
                    // tempPanes[index].customChoices = [];
                    setPanes(tempPanes);
                  }}>
                  <Select.Option value={QUESTION_TYPE.READ_SENTENCE}>
                    {QUESTION_TYPE.READ_SENTENCE} (default)
                  </Select.Option>

                  {/*MUST ENTER 4 Choices*/}
                  <Select.Option value={QUESTION_TYPE.CHOICES}>
                    {QUESTION_TYPE.CHOICES}
                  </Select.Option>

                  {/*MUST ENTER 8 Choices*/}
                  <Select.Option value={QUESTION_TYPE.BUILD_SENTENCE}>
                    {QUESTION_TYPE.BUILD_SENTENCE}
                  </Select.Option>

                  {/*MUST ENTER TEXTAI*/}
                  <Select.Option value={QUESTION_TYPE.MISSING_LETTERS}>
                    {QUESTION_TYPE.MISSING_LETTERS}
                  </Select.Option>
                  <Select.Option value={QUESTION_TYPE.MISSING_WORD}>
                    {QUESTION_TYPE.MISSING_WORD}
                  </Select.Option>
                  {/*MUST ENTER 4 Choices*/}
                  <Select.Option value={QUESTION_TYPE.COMPREHENSION}>
                    {QUESTION_TYPE.COMPREHENSION}
                  </Select.Option>
                  {/* TRANSCRIBE_AND_SCORE */}
                  <Select.Option value={QUESTION_TYPE.TRANSCRIBE_AND_SCORE}>
                    {QUESTION_TYPE.TRANSCRIBE_AND_SCORE}
                  </Select.Option>
                  {/* DESCRIBE_IMAGE_ONLY_PRONUNCIATION */}
                  <Select.Option
                    value={QUESTION_TYPE.DESCRIBE_IMAGE_ONLY_PRONUNCIATION}>
                    {QUESTION_TYPE.DESCRIBE_IMAGE_ONLY_PRONUNCIATION}
                  </Select.Option>
                  {/* FREE_TEXT_ONLY_PRONUNCIATION */}
                  <Select.Option
                    value={QUESTION_TYPE.FREE_TEXT_ONLY_PRONUNCIATION}>
                    {QUESTION_TYPE.FREE_TEXT_ONLY_PRONUNCIATION}
                  </Select.Option>
                </Select>
              </div>
              {pane.type === QUESTION_TYPE.BUILD_SENTENCE ? (
                <div>
                  {new Array(8).fill(null).map((_, arrayIndex) => {
                    return (
                      <div style={{marginTop: '20px', marginBottom: '20px'}}>
                        <Text>
                          Question{' '}
                          {arrayIndex < 4 ? 'first part of' : 'second part of'}{' '}
                          sentence {arrayIndex + 1}
                        </Text>
                        <Input
                          value={panes[index].choices[arrayIndex]}
                          onChange={(e) => {
                            let tempPanes = [...panes];
                            //@ts-ignore
                            tempPanes[index].choices[arrayIndex] =
                              e.target.value;
                            console.log('tempPanes[index]->', tempPanes[index]);
                            setPanes(tempPanes);
                          }}
                          placeholder={`Question sentence ${arrayIndex + 1}`}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : pane.type === QUESTION_TYPE.CHOICES ? (
                <div>
                  {new Array(4).fill(null).map((_, arrayIndex) => {
                    return (
                      <div style={{marginTop: '20px', marginBottom: '20px'}}>
                        <Text>Choice number {arrayIndex + 1}</Text>
                        <Input
                          value={panes[index].choices[arrayIndex]}
                          onChange={(e) => {
                            let tempPanes = [...panes];
                            //@ts-ignore
                            tempPanes[index].choices[arrayIndex] =
                              e.target.value;

                            setPanes(tempPanes);
                          }}
                          placeholder={`Choice number ${arrayIndex + 1}`}
                        />
                      </div>
                    );
                  })}

                  <div style={{marginTop: '20px', marginBottom: '20px'}}>
                    <Text>Question text</Text>
                    <Input
                      value={panes[index].text}
                      onChange={(e) => {
                        let tempPanes = [...panes];
                        tempPanes[index].text = e.target.value;
                        setPanes(tempPanes);
                      }}
                      placeholder="Question text"
                    />
                  </div>
                </div>
              ) : (
                <div style={{marginTop: '20px', marginBottom: '20px'}}>
                  <Text>Question text</Text>
                  <Input
                    value={panes[index].text}
                    onBlur={() => {
                      console.log('onBLUEERR', panes[index].text);

                      if (
                        pane.text &&
                        pane.type === QUESTION_TYPE.COMPREHENSION
                      ) {
                        const choicesOptions = pane.text.split(' ');
                        let newOptions = {};
                        const numOfchoiceOptions = choicesOptions.filter(
                          (text) => text.startsWith('CHOICES'),
                        );

                        if (numOfchoiceOptions.length === 0) {
                          alert(
                            'Must Text Contains at least one keyword CHOICES1',
                          );
                        }
                        console.log(
                          'panes[index]?.customChoices',
                          panes[index]?.customChoices,
                        );
                        if (
                          !panes[index].customChoices ||
                          numOfchoiceOptions.length !==
                            Object.keys(panes[index].customChoices).length
                        ) {
                          setSelectChoice(null);
                        }
                        console.log('numOfchoiceOptions', numOfchoiceOptions);
                        let currectCustomObject = JSON.parse(
                          JSON.stringify(panes[index]?.customChoices),
                        );

                        numOfchoiceOptions.forEach((value) => {
                          let currentChoicesValues = [];

                          if (
                            value &&
                            currectCustomObject &&
                            currectCustomObject?.hasOwnProperty(value)
                          ) {
                            currentChoicesValues = currectCustomObject[value];
                          }

                          newOptions[value] = [...currentChoicesValues];
                          currentChoicesValues = [];
                        });
                        console.log('newOptions', newOptions);
                        let tempPanes = [...panes];
                        //@ts-ignore
                        tempPanes[index].customChoices = newOptions;
                        setPanes(tempPanes);
                      } else {
                        let tempPanes = [...panes];

                        //@ts-ignore
                        tempPanes[index].customChoices = {};
                        setPanes(tempPanes);
                      }
                    }}
                    onChange={(e) => {
                      let tempPanes = [...panes];
                      tempPanes[index].text = e.target.value;
                      // store the original ai text to able the user change the correct answer
                      //@ts-ignore
                      tempPanes[index].originalTextAI = e.target.value;
                      setPanes(tempPanes);
                    }}
                    placeholder="Question text"
                  />
                </div>
              )}
              <div
                style={{
                  marginTop: '20px',
                  marginBottom: '20px',
                  border: '2px solid red',
                }}>
                <Text>
                  {pane.type !== QUESTION_TYPE.COMPREHENSION
                    ? 'Question AI text'
                    : 'Correct Answer'}
                </Text>
                <Input
                  value={panes[index].textAI}
                  onChange={(e) => {
                    let tempPanes = [...panes];
                    tempPanes[index].textAI = e.target.value;
                    setPanes(tempPanes);
                  }}
                  placeholder="Question AI text"
                />
              </div>
              {pane.type === QUESTION_TYPE.COMPREHENSION &&
                Object.keys(pane?.customChoices ?? {}).length > 0 &&
                panes && (
                  <div>
                    <div>
                      <Text style={{display: 'block'}}>Choice Options </Text>

                      <Select
                        value={selectChoice}
                        showSearch
                        style={{width: '30%'}}
                        placeholder={
                          'Select ' + selectChoice ? selectChoice : ''
                        }
                        optionFilterProp="children"
                        onChange={(selectedTestType: TestTypes) => {
                          setSelectChoice(selectedTestType);

                          let tempPanes = [...panes];
                          //@ts-ignore

                          setPanes(tempPanes);
                        }}>
                        {Object.keys(panes[index].customChoices).map(
                          (choice) => (
                            <Select.Option value={choice}>
                              {choice}
                            </Select.Option>
                          ),
                        )}
                      </Select>
                    </div>
                    {selectChoice && (
                      <div>
                        {(
                          panes[index]?.customChoices?.[selectChoice] ||
                          new Array(2).fill('')
                        ).map((choiceValue, arrayIndex) => (
                          <div
                            key={arrayIndex}
                            style={{marginTop: '20px', marginBottom: '20px'}}>
                            <Row align="middle">
                              <Text>
                                Choice number {arrayIndex + 1}
                                <Checkbox
                                  style={{marginLeft: '10px'}}
                                  checked={
                                    //@ts-ignore
                                    panes[index].correctIndex?.[
                                      selectChoice
                                    ] === arrayIndex
                                  }
                                  onChange={() => {
                                    let tempPanes = [...panes];
                                    //@ts-ignore
                                    if (!tempPanes[index].correctIndex) {
                                      //@ts-ignore
                                      tempPanes[index].correctIndex = {};
                                    }
                                    //@ts-ignore
                                    tempPanes[index].correctIndex[
                                      selectChoice
                                    ] = arrayIndex;
                                    let aiText =
                                      //@ts-ignore
                                      tempPanes[index].originalTextAI;
                                    let normalText =
                                      //@ts-ignore
                                      tempPanes[index].text;
                                    Object.keys(
                                      //@ts-ignore
                                      tempPanes[index].correctIndex,
                                    ).forEach((choiceKey) => {
                                      const correctIndex =
                                        //@ts-ignore
                                        tempPanes[index].correctIndex[
                                          choiceKey
                                        ];
                                      const selectedValue =
                                        tempPanes[index].customChoices[
                                          choiceKey
                                        ]?.[correctIndex] || choiceKey;
                                      aiText = aiText.replace(
                                        new RegExp(choiceKey, 'g'),
                                        selectedValue,
                                      );
                                      // Clean up any existing duplicates of the placeholder
                                      const cleanedText = normalText.replace(
                                        new RegExp(
                                          `\\b${choiceKey}(\\s+${choiceKey})*\\b`,
                                          'g',
                                        ),
                                        choiceKey,
                                      );
                                      // Duplicate the placeholder to match the word count of the selected value
                                      const wordCount = selectedValue
                                        .trimEnd()
                                        .split(/\s+/).length;
                                      const duplicatedKey = Array(wordCount)
                                        .fill(choiceKey)
                                        .join(' ');
                                      normalText = cleanedText.replace(
                                        new RegExp(choiceKey, 'g'),
                                        duplicatedKey,
                                      );
                                    });
                                    // update the stored value with the updated with the selected answer count
                                    tempPanes[index].text = normalText;
                                    // Update the pane's textAI
                                    tempPanes[index].textAI = aiText;

                                    setPanes(tempPanes);
                                  }}
                                />{' '}
                                Correct answer
                              </Text>
                            </Row>
                            <Input
                              value={
                                panes[index].customChoices[selectChoice][
                                  arrayIndex
                                ] || ''
                              }
                              onChange={(e) => {
                                let tempPanes = [...panes];
                                const currentChoices =
                                  tempPanes[index].customChoices[
                                    selectChoice
                                  ] || [];
                                currentChoices[arrayIndex] = e.target.value;

                                tempPanes[index].customChoices[selectChoice] =
                                  currentChoices;
                                setPanes(tempPanes);
                              }}
                              placeholder={`Choice number ${arrayIndex + 1}`}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              <div>
                <Text style={{fontSize: '20', fontWeight: 'bold'}}>
                  Question asset
                </Text>

                <CreateAssetBlock
                  asset={panes[index].asset}
                  defaultAssetType={
                    panes[index]?.asset?.type ?? AssetType.PHOTO
                  }
                  // @ts-ignore TODO be checked
                  onChange={(asset) => {
                    // console.log(
                    //   'panes[index].asset.type',
                    //   panes[index].asset.type,
                    // );

                    let tempPanes = [...panes];
                    tempPanes[index].asset = asset;
                    setPanes(tempPanes);
                  }}
                />
              </div>
              <div>
                <Text style={{fontSize: '20', fontWeight: 'bold'}}>
                  Question correct answer asset file url
                </Text>

                {panes[index]?.correctAsset?.fileUri ? (
                  <Row
                    style={{
                      display: 'flex',
                      // justifyContent: 'center',
                      alignItems: 'center',
                      alignContent: 'center',
                    }}>
                    <Input
                      style={{width: '80%'}}
                      value={panes[index]?.correctAsset?.fileUri}
                      onChange={(e) => {}}
                      placeholder="Correct answer url"
                    />

                    <CloseCircleFilled
                      style={{marginInline: 20}}
                      onClick={() => {
                        setFileAudio(null);

                        let tempAsset = {
                          fileUri: '',
                          type: AssetType.AUDIO,
                          thumbnailUri: '',
                        };
                        let tempPanes = [...panes];
                        // @ts-ignore
                        tempPanes[index].correctAsset = tempAsset;
                        setPanes(tempPanes);
                      }}
                    />
                  </Row>
                ) : (
                  <Row>
                    <Upload {...propsAudioUpload}>
                      <Button
                        disabled={fileAudio ? true : false}
                        icon={<UploadOutlined />}>
                        Select File
                      </Button>
                    </Upload>

                    <Button
                      type="primary"
                      onClick={() =>
                        handleUpload(
                          fileAudio,
                          UploadFileType.TEST_MEDIA,
                          index,
                        )
                      }
                      disabled={!fileAudio}
                      loading={uploading}>
                      {uploading ? 'Uploading' : 'Upload'}
                    </Button>
                  </Row>
                )}
              </div>
            </TabPane>
          ))}
        </Tabs>

        <Button
          onClick={async () => {
            setIsLoading(true);
            let test: any = {
              id: selectedtest.id,
              updatedAt: selectedtest.updatedAt,
              questions: panes,
              type: testType,
              name: testName,
              description: testDescription,
              summary: testSummary,
              asset: testAsset,
            };
            console.log('ASSSSS', test);
            // @ts-ignore
            let testValidationResult = validateCreateTest(test);

            if (testValidationResult?.status) {
              await testService
                .updateTest(test, selectedtest)
                .catch((e) => {
                  setIsLoading(true);
                  alert('Error updating test');
                })
                .then((response) => {
                  setIsLoading(false);
                  alert('Test with ID: ' + response?.id + ' updated');
                  history.push('/tests');
                });
            } else {
              alert(testValidationResult.message);
              setIsLoading(false);
            }
          }}>
          Edit
        </Button>
      </div>
      <Modal
        okButtonProps={{hidden: true}}
        cancelButtonProps={{hidden: true}}
        visible={isLoading}
        closable={true}>
        <div>
          <Spin size="large" style={{margin: 'auto', width: '100%'}} />
          <p style={{textAlign: 'center'}}>Loading...</p>
        </div>
      </Modal>
    </>
  );
};
