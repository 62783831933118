import React from 'react';

import {AssetType, QUESTION_TYPE} from '../../graphql/API';
import './index.css';
import {Modal, Typography} from 'antd';
import ComprehensionQuestion from './comprehensionQuestion';
import {Question} from '../../graphql/API';
import BasicImageDisplayAuth from '../../components/BasicImageDisplayAuthS3';
const {Text} = Typography;
type modalPropsType = {
  question: Question;
  closeModal: () => void;
};
export class QuestionModal extends React.Component<modalPropsType> {
  constructor(props: any) {
    super(props);
  }

  renderPhoto = () => {
    const {question} = this.props;
    return (
      <>
        <div
          style={{
            position: 'relative',
            display: 'inline',
            top: 0,
            left: 0,
            backgroundColor: 'blue',
          }}>
          {question?.correctAsset?.fileUri! && (
            <audio
              // @ts-ignore
              ref={audioRef}
              style={{display: 'none'}}
              id="audio"
              controls
              src={question?.correctAsset?.fileUri!}
            />
          )}
          <BasicImageDisplayAuth
            thumbnailUri={question?.asset?.thumbnailUri}
            style
          />
        </div>
      </>
    );
  };

  renderQuestion = () => {
    const {question} = this.props;
    switch (question?.asset?.type) {
      case AssetType.PHOTO:
        return this.renderPhoto();
      case AssetType.VIDEO:
        return <>on it</>;
    }
  };

  render() {
    const {closeModal, question} = this.props;
    return (
      <Modal
        closable={false}
        className={'exercise-modal'}
        style={{maxHeight: '100%'}}
        width={'50%'}
        centered={true}
        title={
          question?.description &&
          question?.type === QUESTION_TYPE.DESCRIBE_IMAGE_ONLY_PRONUNCIATION
            ? 'Describe the attached image'
            : question?.description
        }
        onCancel={closeModal}
        open={true}
        onOk={() => {}}
        footer={<></>}>
        <div style={{width: '100%', textAlign: 'left'}}>
          <div>Question Type: {question.type}</div>
          <div
            className="Modal_Res_Test"
            style={{
              marginTop: '20px',
              position: 'relative',
            }}>
            {this.renderQuestion()}
          </div>

          {question?.type === 'COMPREHENSION' ? (
            <ComprehensionQuestion
              text={question?.text || ''}
              choices={JSON.parse(question?.customChoices || '')}
              closeModal={closeModal}
            />
          ) : (
            <Text
              className={'test-or-question-text'}
              style={{
                top: 'auto',
                display: 'inline-block',
                alignSelf: 'left',
                textAlign: 'left',
                fontSize: 20,
              }}>
              {question?.type ===
              QUESTION_TYPE.DESCRIBE_IMAGE_ONLY_PRONUNCIATION
                ? ''
                : question?.text}
            </Text>
          )}
        </div>
      </Modal>
    );
  }
}
